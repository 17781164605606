const OnDarkSectionClassname = 'on-dark-section'

class HeaderLogoController {
  constructor() {
    this.logoImg = document.getElementById('logo-img')
    this.logoImgWhite = document.getElementById('logo-img-white')
    this.currentLogo = this.logoImg

    this.fixedItems = []
    this.darkSections = []

    this.buildFixedElementsAndDarkSections()

    window.addEventListener('scroll', () => this.checkIfFixedElementsOnDarkBackground())
    window.addEventListener('resize', () => this.checkIfFixedElementsOnDarkBackground())
    window.onload = () => this.buildFixedElementsAndDarkSections()
  }

  buildFixedElementsAndDarkSections() {
    this.fixedItems = []
    this.darkSections = []
    const fixedItemElements = [
      ...document.querySelectorAll("header nav ul li"),
      ...document.querySelectorAll("ul#social-list svg")
    ]
    const darkSectionElements = [
      document.querySelector("section#section-gallery-teaser"),
      document.querySelector("section#section-lineup"),
      document.querySelector("div#footer-info")
    ]

    fixedItemElements.forEach(fixedItem => {
      const fixedItemHeight = fixedItem instanceof SVGSVGElement ?
        fixedItem.getBoundingClientRect().height :
          fixedItem.offsetHeight

      this.fixedItems.push({
        fixedItem: fixedItem,
        top: fixedItem.getBoundingClientRect().top,
        bot: fixedItem.getBoundingClientRect().top + fixedItemHeight,
        height: fixedItemHeight
      })
    })

    darkSectionElements.forEach(darkSection => {
      if(darkSection === null) return false
      this.darkSections.push({
        darkSection: darkSection,
        top: darkSection.offsetTop,
        bot: darkSection.offsetTop + darkSection.offsetHeight,
        height: darkSection.offsetHeight
      })
    })

    this.checkIfFixedElementsOnDarkBackground()
  }

  /**
   * @param {Boolean} v true for white, false for normal variant
   */
  setLogoVariant(v) {
    if (v) {
      if (this.currentLogo === this.logoImgWhite) return;
      this.logoImg.classList.add('logo-img-hidden')
      this.logoImgWhite.classList.remove('logo-img-hidden')
    } else {
      if (this.currentLogo === this.logoImg) return;
      this.logoImgWhite.classList.add('logo-img-hidden')
      this.logoImg.classList.remove('logo-img-hidden')
    }
    this.currentLogo = v ? this.logoImgWhite : this.logoImg
  }

  checkIfFixedElementsOnDarkBackground() {
    this.fixedItems.forEach(fixedItem => {
      const fixedItemOnDarkBackground = this.darkSections.some(darkSection => {
        const fixedItemScrollTop = fixedItem.top + window.pageYOffset - document.documentElement.clientTop
        const fixedItemScrollBot = fixedItem.bot + window.pageYOffset - document.documentElement.clientTop
        const overlapPixels = Math.max(
          0,
          Math.min(darkSection.bot, fixedItemScrollBot) - Math.max(darkSection.top, fixedItemScrollTop)
        )
        const overlapPercent = overlapPixels/fixedItem.height * 100
        return overlapPercent > 50
      })

      if (fixedItemOnDarkBackground) {
        fixedItem.fixedItem.classList.add(OnDarkSectionClassname)
      } else {
        fixedItem.fixedItem.classList.remove(OnDarkSectionClassname)
      }
    })

    //do the same for the logo
    const isLogoOnDarkBackground = this.darkSections.some(darkSection => {
      const fixedItemScrollTop = this.currentLogo.offsetTop + window.pageYOffset - document.documentElement.clientTop
      const fixedItemScrollBot = this.currentLogo.offsetTop + this.currentLogo.offsetHeight + window.pageYOffset - document.documentElement.clientTop
      const overlapPixels = Math.max(
        0,
        Math.min(darkSection.bot, fixedItemScrollBot) - Math.max(darkSection.top, fixedItemScrollTop)
      )
      const overlapPercent = overlapPixels/this.currentLogo.height * 100
      return overlapPercent > 50
    })

    this.setLogoVariant(isLogoOnDarkBackground)
  }
}

export let headerController

document.addEventListener("DOMContentLoaded", function(event) {
  headerController = new HeaderLogoController()
  setTimeout(() => headerController.buildFixedElementsAndDarkSections(), 5000)
})
